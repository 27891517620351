/* add css module styles here (optional) */

._styles-module__test__3ybTi {
  margin: 2em;
  padding: 0.5em;
  border: 2px solid #000;
  font-size: 2em;
  text-align: center;
}

._styles-module__scrollbar__3Sxu7::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}
._styles-module__scrollbar__3Sxu7::-webkit-scrollbar-track {
  background: #ccc;
}
._styles-module__scrollbar__3Sxu7::-webkit-scrollbar-thumb {
  background: #007bffcc;
}
._styles-module__scrollbar__3Sxu7::-webkit-scrollbar-thumb:hover {
  background: #007bff;
}